// Init Mathjax
//
// uses:
// - https://github.com/mathjax/MathJax
//
(function (window) {
  function initMathJax() {
    let mathEl = document.querySelectorAll('[data-typeset-mathjax]');
    if (mathEl.length) {
      if (typeof window.MathJax == 'undefined') {
        // Initial config for MathJax
        window.MathJax = {
          startup: {
            ready: () => {
              MathJax.config.chtml.fontURL =
                window.APU.mathjaxFontPath + 'woff-v2';
              MathJax.config.chtml.font.options.fontURL =
                window.APU.mathjaxFontPath + 'woff-v2';
              MathJax.startup.defaultReady();
            },
          },
        };

        // Load MathJax
        (function () {
          var script = document.createElement('script');
          script.src = window.APU.mathjaxJsPath;
          script.async = true;
          document.head.appendChild(script);
        })();
      } else {
        window.MathJax.typesetPromise();
      }
      mathEl.forEach((el) => el.removeAttribute('hidden'));
    }
  }

  document.addEventListener('pjax:success', initMathJax);
  initMathJax();
})(this);
